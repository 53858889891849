import { LocaleMessage, TranslateResult } from "vue-i18n"

export default class Utils {
  static getErrorMessage(
    e: any,
    defaultMsg: string | TranslateResult | LocaleMessage = ""
  ): string | TranslateResult | LocaleMessage {
    const resp = e.response
    if (resp && resp.data) {
      if (resp.data.errors) {
        const firstKey = Object.keys(resp.data.errors)[0]
        if (Array.isArray(resp.data.errors[firstKey])) {
          return resp.data.errors[firstKey][0]
        }
        return resp.data.errors[firstKey]
      } else if (resp.data.message) {
        return resp.data.message
      } else if (Array.isArray(e.response.data) && e.response.data.length > 0) {
        return resp.data[0].message
      }
    }

    return defaultMsg
  }

  static objectToDictionary(obj: any) {
    const params: { [name: string]: any } = {}
    Object.keys(obj).forEach((key) => {
      params[key] = obj[key]
    })
    return params
  }

  static invokeDownload(resp: any) {
    const fileName = resp.headers["content-disposition"]
      .split("filename=")[1]
      .replace(/"/g, "")
    const blob = new Blob([resp.data], { type: "application/pdf" })
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = fileName
    link.click()
    URL.revokeObjectURL(link.href)
  }
}
