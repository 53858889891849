









import { LocaleMessage, TranslateResult } from "vue-i18n"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

export interface SnackbarMsg {
  message: string | TranslateResult | LocaleMessage
  color: string
}

@Component
export default class SimpleSnackbar extends Vue {
  @Prop({
    default: () => {
      return { message: "", color: "error" }
    },
    type: Object as () => SnackbarMsg,
  })
  private readonly snackbarMsg!: SnackbarMsg

  private showBar = false
  private snackbarColor = "error"

  @Watch("snackbarMsg")
  onShowChange(newVal: SnackbarMsg) {
    if (newVal.color) {
      this.snackbarColor = newVal.color
    }
    this.showBar = true
  }
}
