

















import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import SimpleSnackbar, { SnackbarMsg } from "@/components/SimpleSnackbar.vue"
import { LocaleMessage, TranslateResult } from "vue-i18n"
import Utils from "@/utils/Utils"

@Component({
  components: {
    SimpleSnackbar,
  },
})
export default class BaseLayout extends Vue {
  @Prop({ default: false }) private readonly loadingScreen!: boolean
  @Prop({ type: Object as () => SnackbarMsg })
  private readonly snackbarMsg!: SnackbarMsg

  private snackbarMessage: Partial<SnackbarMsg> = { message: "" }
  public loading = false

  @Watch("loadingScreen")
  onLoadingScreen(newVal: boolean) {
    this.loading = newVal
  }

  protected toastSuccessMsg(msg: string | TranslateResult | LocaleMessage) {
    this.snackbarMessage = { message: msg, color: "success" }
  }

  protected toastErrorMsg(msg: string | TranslateResult | LocaleMessage) {
    this.snackbarMessage = { message: msg, color: "error" }
  }

  protected toastExceptionMsg(
    e: any,
    defaultMsg: string | TranslateResult | LocaleMessage = ""
  ) {
    this.snackbarMessage = {
      message: Utils.getErrorMessage(e, defaultMsg),
      color: "error",
    }
  }
}
